import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    Province: "Province",
    Distrit: "Distrit",
    Locality: "Locality",
    "Cases per Province": "Cases per Province",
    "CLIENTE PROFILE BY AGE": "CLIENTE PROFILE BY AGE",
    Reports: "Reports",
    "Advanced Report Filters": "Advanced Report Filters",
    General: "General",
    Tasks: "Tasks",
    Cases: "Cases",
    Home: "Home",
    "Registered Cases": "Registered Cases",
    "Fowarded Cases": "Fowarded Cases",
    "Lists & Forms": "Lists & Forms",
    Lists: "Lists",
    "Green Line 1458": "Green Line 1458",
    Advanced: "Advanced",
    Registered: "Registered",
    Fowarded: "Fowarded",
    "KOWLEDGE ABOUT LV": "KOWLEDGE ABOUT LV",
    SATISFATION: "SATISFATION",
    "CASES BY PROVINCES": "CASES BY PROVINCES",
    "CASE TYPE": "CASE TYPE",
    "CASES PER SECTOR": "CASES PER SECTOR",
    "Total Cases Registered": "Total Cases Registered",
    "Total Feedback Provided": "Total Feedback Provided",
    "User role not provided": "User role not provided",
    "Dashboard & Analyptics": "Dashboard & Analyptics",
    Dashboard: "Dashboard",
    "CLIENTE PROFILE BY GENDER": "CLIENTE PROFILE BY GENDER",
    Pages: "Pages",
    "Consent Pi": "Consent to Collect Personal Information",
    "Consent Share Pi": "Consent to share Personal Information",
    "Resettlement name": "Resettlement name",
    "Case category": "Case category",
    Status: "Status",
    "Knowledge Base": "Knowledge Base",
    "Sub-category": "Sub-category",
    "Sub-category issue": "Sub-category issue",
    "Who is not receiving assistance": "Who is not receiving assistance",
    Sector: "Sector",
    "Callback Required": "Callback Required",
    "Case Number": "Case Number",
    "Unavailable Contact": "Unavailable Contact",
    Yes: "Yes",
    Male: "Male",
    No: "No",
    Select: "Select",
    Lvform: "Case",
    "Start Date": "Start Data",
    "End Date": "End Date",
    "Number of attempts to reach the other person":
      "Number of attempts to reach the other person",
    Vulnerability: "Vulnerability",
    "Call Notes": "Call Notes",
    "Send Feedback": "Send Feedback",
    "Not started": "Not started",
    "In Progress": "In Progresso",
    Completed: "Completed",
    "Request for information": "Request for information",
    "Call Solution": "Call Solution",
    "Case Priority": "Case Priority",
    "Case closed": "Case closed",
    "Means of Communication": "Means of Communication",
    "How would you prefer to be reached?":
      "How would you prefer to be reached?",
    "Accommodation or resettlement centre":
      "Accommodation or resettlement centre",
    "Full Name": " Full Name",
    Contact: "Contact",
    "Add New": "Add New",
    Age: "Age",
    Female: "Female",
    "Not specified": "Not specified",
    Irrelevant: "Irrelevant",
    Community: "Community",
    "Distribution Point": "Distribution Point",
    "Transfer modality": "Transfer modality",
    "Who is contacting": "Who is contacting",
    "Task Title": "Task Title",
    Description: "Description",
    "Assigned to": "Assigned to",
    Gender: "Gender",
    Medium: "Medium",
    High: "High",
    Low: "Low",
    "Positive feedback": "Positive feedback",
    "Technical problems": "Technical problems",
    Access: "Access",
    "Distribution issue": "Distribution issue",
    "Abuse of power": "Abuse of power",
    "Sexual Exploitation and Abuse": "Sexual Exploitation and Abuse",
    Quality: "Quality",
    Quantity: "Quantity",
    "FFA Activity": "FFA Activity",
    "Safety problems": "Safety problems",
    Entitlement: "Entitlement",
    "Distribution timing": "Distribution timing",
    "Duration of assistance": "Duration of assistance",
    Food: "Food",
    "Non-Food Items": "Non-Food Items",
    "Assistance card not working": "Assistance card not working",
    "Flood assistance": "Flood assistance",
    "Delete personal information": "Delete personal information",
    Action: "Action",

    false: "No",
    true: "Yes",
    "Case Close Category": "How did it end",
    "Is Closed": "Case closed?",
    article: "Article",
    "Cluster Sector": "Cluster Name",

    manager: "Manager",
    operator: "Operator",
    focalpoint: "Focalpoint",
    partner: "Partner",
  },
};
