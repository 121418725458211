import en from './en-US';
import pt from './pt-PT';



export default  {

...en,
...pt,

}