import { LOCALES } from "../locales";

export default {
  [LOCALES.PORTUGUESE]: {
    Classification: "Classificação",
    Locations:"Localizações",
    'location classification':"Classificação do Local",
    Province: "Provincia",
    province: "Provincia",
    District: "Distrito",
    Locality: "Localidade",
    "Cases per province": "Casos por Provincia",
    "CLIENTE PROFILE BY AGE": "PERFIL DO CLIENTE POR IDADE",
    Reports: "Relatórios",
    "Advanced Report Filters": "Filtros de relatorios Avancados",
    General: "Geral",
    Tasks: "Tarefas",
    Cases: "Casos",
    Home: "Inicio",
    Advanced: "Avançado",
    Registered: "Resgistados",
    Fowarded: "Encaminhados",
    Forwarded: "Encaminhados",
    "Knowledge Base": "Base de Conhecimento",
    "Consent Share Pi": "Consentimento para compartilhar com parceiro",
    "Full Name": "Nome Completo",
    Contact: "Contacto",
    Lvform: "Caso",
    "Task title": "Titulo da Tarefa",
    Description: "Descrição",
    "Assigned to": "Atribuído a",
    Age: "Idade",
    Community: "Comunidade",
    "Distribution Point": "Ponto de Distribuição",
    "Transfer modality": "Modalidade de Transferência",
    "Resettlement name": "Nome de Reassentamento",
    "Accommodation or resettlement centre":
      "Acomodação ou Centro de Reassentamento",
    "Case category": "Tipo de Caso",
    "Sub-category": "Subcategoria",
    "Sub-category issue": "Problema de Subcategoria",
    "Who is not receiving assistance": "Quem não está recebendo assistência",
    Sector: "Sector",
    'Case tipology':'Tipo de Caso',
    Vulnerability: "Vulnerabilidade",
    "Call Notes": "Notas da Chamada",
    "Call Solution": "Solução da Chamada",
    "Start Date": "Data de Inicio",
    "End Date": "Data de Termino",
    "Number of attempts to reach the other person":
      "Número de tentativas para entrar em contato com a outra pessoa",
    "call_attempts":
      "Número de tentativas para entrar em contato com a outra pessoa",
    "Send Feedback": "Enviar Comentarios",
    "Not started": "Não foi iniciado",
    "In Progress": "Em Progresso",
    Completed: "Encerrado",
    "A valid integer is required.":"É necessário um número válido",
    "Case Priority": "Prioridade de Caso",
    "Case closed": "Caso Encerrado",
    "Means of Communication": "Meios de Comunicação",
    "Who is contacting": "Quem está a comunicar?",
    "Consent Pi": "Consentimento para coletar informações pessoais",
    "KOWLEDGE ABOUT LV": "CONHECIMENTO SOBRE LV",
    "How would you prefer to be reached?": "Como gostaria de ser contatado?",
    "How do you feel about how your query was dealt with during this call?":
      "Como você se sente sobre como sua consulta foi tratada durante esta chamada?",
    "How have you come to know about the complaints and feedback mechanism?":
      "Como você ficou sabendo do mecanismo de reclamações e feedback?",
    SATISFATION: "SATISFAÇÃO",
    "CALLER PROFILE BY AGE": "PERFIL DO CONTACTANTE POR IDADE",
    "CALLER PROFILE BY GENDER": "PERFIL DO CONTACTANTE POR SEXO",
    "Callback Required": "Ligação de volta",
    "Case Number": "Numero do Caso",
    "CASES BY PROVINCES": "CASOS POR PROVINCIA",
    "CASE TYPE": "TIPO DE CASO",
    Action: "Acção",
    "Not specified": "Não especificado",
    "Registered Cases": "Casos Registados",
    "Fowarded Cases": "Casos Encaminhados",
    "CASES PER SECTOR": "CASOS POR SECTOR",
    "Lists & Forms": "Listas & Formulários",
    Lists: "Listas",
    "Green Line 1458": "Linha Verde 1458",
    "User role not provided": "Função do usuário não fornecida",
    "Dashboard & Analytics": "Painel e análise",
    "Analysis and Reporting": "Análise e Relatórios",
    Dashboard: "Painel",
    "Total Cases Registered": "Total de Casos Registados",
    "Total Feedback Provided": "Feedback total fornecido",
    "CLIENTE PROFILE BY GENDER": "PERFIL DO CLIENTE POR GÊNERO",
    Pages: "Páginas",
    // Páginas: "Páginas",
    "Unavailable Contact": "Chamador não encontrado para feedback",
    "Add New": "Adicionar novo",
    Yes: "Sim",
    No: "Não",
    "Add File": "Adicionar Arquivo",
    Select: "Seleccionar",

    _comment: "_____ Case tipology Section _____",

    Priority: "Prioridade",
    Medium: "Médio",
    High: "Alto",
    Low: "Baixo",

    "Who is communicating?": "Quem está a comunicar?",
    Beneficiary: "Beneficiário",
    "Representative of beneficiary": "Alguém para beneficiário",
    "Non beneficiary": "Não beneficiário",
    "Community leader": "Líder comunitário",
    "Humanitarian partner": "Parceiro Humanitário",
    Other: "Outro(s)",

    Gender: "Gênero",
    Male: "Masculino",
    Female: "Feminino",
    "Not disclosed": "Não mencionado",

    Food: "Comida",
    "Value voucher": "Senha de dinheiro",
    Money: "Dinheiro",
    "Commodity voucher": "Senha de bens",
    NFI: "Bens não alimentares",
    // "Non-food Items": "Bens não alimentares",
    "Not relevant": "Irrelevante",
    FFA: "FFA",
    "School feeding": "Alimentação escolar",

    "Complaint/Negative Feedback": "Reclamação / Feedback Negativo",
    "Positive feedback": "Feedback Positivo",
    "Request for Information": "Pedido de Informação",
    "Request for Assistance": "Pedido de Assistência",
    "Data amendment": "Alteração de Dados",
    "Request for information": "Pedido De Informação",
    "Technical problems": "Problemas Técnicos",
    "Person with disability": "Pessoa com Deficiência",
    "Child headed household": "Família Chefiada por Criança",
    "Children Protection": "Proteção Infantil",
    "Social Protection": "Proteção Social",
    "Single parent": "Pais Solteiros",
    "Pregnant or lactating woman": "Mulher Grávida ou Lactente",
    "Elderly head of household": "Família Chefiada por Idosos",
    "Chronically patient": "Doente Crónico",
    None: "Nenhum(a)",
    Null: "Indefinido",
    "Please wait": "Por favor aguarde",
    CCCM: "CCCM",
    Information: "Informação",
    Education: "Educação",
    ETC: "ETC",
    "Food Security": "Segurança Alimentar",
    Health: "Saúde",
    Logistics: "Logistica",
    Nutrition: "Nutrição",
    Protection: "Proteção",
    Shelter: "Abrigo",
    WASH: "Agua, saneamento e Higiene",
    Government: "Governo",
    "Linha verde (own phone)": "Linha Verde (próprio número)",
    "Linha verde (borrowed phone)": "Linha Verde (telefone emprestado)",
    "WFP hotline (own phone)": "WFP Hotline (próprio número)",
    "WFP hotline (borrowed phone)": "WFP Hotline (telefone emprestado)",
    Helpdesk: "Mesa de Apoio",
    sms: "sms",
    Email: "Email",
    "Suggestion box": "Caixa de Sugestões",
    Radio: "Radio",
    Pamphlet: "Panfletos",
    "People working in the community": "Pessoas Trabalhando na Comunidade",
    SMS: "SMS",
    "Posters or other visibility material":
      "Cartazes ou material de visibilidade",
    "Same phone": "Mesmo Contacto",
    "Other phone ": "Outro Contacto",
    "Very satisfied": "Muito Satisfeito",
    Satisfied: "Satisfeito",
    Neutral: "Neutro",
    Unsatisfied: "Insatisfeito",
    "Very unsatisfied": "Muito Insatisfeito",
    PSEA: "Proteção Contra Exploração e Abuso Sexual",
    "Protection from Sexual Exploitation and Abuse":
      "Proteção Contra Exploração e Abuso Sexual",

    Access: "Acesso",
    "Distribution issue": "Problemas de Distribuição",
    "Abuse of power": "Abuso de Poder",
    "Exclusion error": "Erros de Exclusão",
    "Undignified/ disrespect": "Indigno/Disrespeito",
    SEA: "Exploração ou Abuso Sexual",
    "Sexual Exploitation and Abuse": "Exploração ou Abuso Sexual",
    Quality: "Qualidade",
    Quantity: "Quantidade",
    "FFA Activity ": "FFA Actividades",
    "Safety problems": "Problemas de Segurança",
    "Child Protection": "Proteção a Criança",
    "Gender-based violence": "Violência Baseada no Gênero",
    GBV: "Violência Baseada no Gênero",
    "Gender-based Violence": "Violência Baseada no Gênero",
    Resilience: "Resiliência",
    "Quantity of mosquito nets": "Quantidade de redes mosquiteiras",
    Entitlement: "Direito de Quantidade a Receber",
    "Distribution timing": "Horario de Distribuição",
    "Duration of assistance": "Duração da Assistência",
    "Use of personal data":
      "Uso de Dados Pessoais",
    "Beneficiary card": "Carta de Apoio",
    "FFA Activity": "FFA Actividades",
    "Targeting criteria": "Critérios de Seleção",
    HR: "RH",
    Symptoms: "Sintomas",
    Prevention: "Prevenção",
    Treatment: "Tratamento",
    "Availability of health services": "Disponibilidade de Serviços de Saúde",
    Myths: "Mitos",
    "Government guidance": "Orientações do Governo",
    "Government Guidelines": "Orientações do Governo",
    "Current situation": "Situação Atual",
    "Impact of Covid-19 on program": "Impacto do Covid-19 no Programa",
    Services: "Serviços",
    "Assistance card not working": "O Cartão de Apoio não Funciona",
    "Flood assistance": "Assistência a Inundações",
    "Lost card": "Perda de Cartão de Apoio",
    "Loss of card/phone": "Perda de Cartão/Telefon",
    "Update HH, personal details": "Atualização de Endereço, Dados Pessoais",
    "Delete personal information": "Eliminar Dados Pessoais",

    Extortion: "Extorsão",
    "Social or cultural obstacles": "Obstáculos Sociais ou Culturais",
    Distance: "Distância",
    Infrastructure: "Infraestrutura",
    Security: "Segurança",
    "Physical vulnerability": "Vulnerabilidade Física",
    Resources: "Recursos",

    // "Political issue": "Motivos Politicos",
    "Political reasons": "Motivos Politicos",
    Fraud: "Fráude",
    Corruption: "Corrupção",
    Diversion: "Desvio",
    Import: "Importar",

    "Never received assistance": "Nunca recebeu assistência",
    "How did they contact us?": "Como está a contactar-nos?",
    "How did you hear about linha verde?":
      "Como teve conhecimento sobre o mecanismo de reclamação e feedback?",
    "How would you like to be contacted?": "Como gostaria de ser contactado?",
    "Other number": "Outro contacto",
    "How do you feel you issue was managed during this call?":
      "Como é que você sente que seu assunto foi tratado durante esta chamada?",

    manager: "Gestor",
    operator: "Operador",
    focalpoint: "Ponto Focal",
    partner: "Parceiro",
    Selected: "Selecionado(s)",

    "Is Active": "Está activo",
    "Email address": "Email",
    "First Name": "Primeiro Nome",
    "Last Name": "Último Nome",
    Groups: "Privilégios",

    Status: "Estado",
    Available: "Disponível",
    Users: "Utilizadores",
    "17 and below": "17 e menos",
    "60 and above": "60 e acima",
    "17 and bellow": "17 e menos",

    "Partner Feedback": "Feedback do Parceiro",
    "My Feedback": "Meu Feedback",
    Save: "Guardar",
    Cancel: "Abortar",
    Submit: "Submeter",
    Edit: "Editar",
    "Read more": "Ler mais",
    "Read Comments": "Ler comentários",
    "More Details": "Mais detalhes",
    "Case Comments History": "Histórico de comentários do caso",
    Update: "Actualizar",
    Task: "Tarefa",
    Send: "Encaminhar",
    "Submission was not possible due to not filling the required fields.": "O envio não foi possível devido ao não preenchimento dos campos obrigatórios.",
    "Add new task": "Adicionar nova tarefa",
    "Comment Your task": "Comente sua tarefa",
    "Could not record feedback message":"Não foi possível gravar a mensagem do feedback",
    "Transaction completed successfuly.": "Transação concluída com sucesso.",
    "Transaction completed successfuly!": "Transação concluída com sucesso!",
    "User created successfully": "Usuário criado com sucesso",
    "Case created successfully": "Caso criado com sucesso",
    "Cluster agency created successfully":"Agência criada com sucesso",
    "Cluster region created successfully":"Regiao criada com sucesso",
    "Cluster sector created successfully":"Sector criado com sucesso",
    "Subcategory issue created successfully":"Sub categoria de problema criada com sucesso",
    "Subcategory created successfully":"Sub categoria criada com sucesso",
    "Case typology created successfully":"Tipo de caso criado com sucesso",
    "Location classification created successfully":"Tipo de Zona adicionada com sucesso",
    "District created successfully":"Distrito adicionado com sucesso",
    "Location created successfully":"localidade  criada com sucesso",
    "Province created successfully":"Provincia adicionada com sucesso",
    // 
    "User updated successfully": "Usuário actualizado com sucesso",
    "Case updated successfully": "Caso actualizado com sucesso",
    "clusteragency  updated successfully":"Agência actualizada com sucesso",
    "clusterregion  updated successfully":"Regiao actualizada com sucesso",
    "clustersector  updated successfully":"Sector actualizado com sucesso",
    "subcategory issue  updated successfully":"Sub categoria de problema actualizada com sucesso",
    "subcategory  updated successfully":"Sub categoria actualizada com sucesso",
    "case typology  updated successfully":"Tipo de caso actualizado com sucesso",
    "location classification  updated successfully":"Tipo de Zona actualizado com sucesso",
    "district updated successfully":"Distrito actualizado com sucesso",
    "location  updated successfully":"localidade  actualizado com sucesso",
    "province updated successfully":"Provincia actualizado com sucesso",
    // 
    "Failed to save this record! Try again!":"Falha ao salvar este registro! Tente novamente!",
    "Unable to save case edit":"Não foi possível gravar a edição do caso",
    "Could not save feedback":"Não foi possível gravar o feedback",
    "Failed to record case":"Falhou ao registar o caso",
    "Submission was not possible due to application 'time-out'": "Submissão não foi possível devido ao 'time-out' da aplicação",
    "Transaction process failed": "Transação falhou.",
    "Could not indicate task status":"Não foi possível indicar o estado da tarefa",
    "Could not edit due to 'time out'.":"Não foi possível editar devido ao 'time out'.",
    "Could not record feedback for the task.":"Não foi possível registar o feedback para a tarefa.",
    "Failed to save Object.": "Falhou em guardar o registo.",
    false: "Não",
    true: "Sim",
    "Register form for task": "Formulário de registro para tarefa",

    INGD: "INGD",
    "IDP Registration": "Registo de Deslocados",
    "Social Protection/INAS": "Proteção Social/INAS",
    "Long lines/overcrowding": "Longas filas/superlotação",
    "Retail price": "Preço de varejo",
    Stock: "Estoque",
    "Health Services": "Serviços de saúde",
    Discrimination: "Discriminação",
    Intimidation: "Intimidação",
    "Abuse of power-  Fraud": "Abuso de poder- Fraude",
    "Abuse of power-  Corruption": "Abuso de poder- Corrupção",
    "Lack of access to water": "Falta de acesso à água",
    "What is Corona virus?": "O que é o  Corona vírus?",
    "Registration process": "Processo de inscrição",
    Mitigation: "Mitigação",
    Forecast: "Previsão",
    "Cyclone confirmation": "Confirmação do ciclone",
    "Sexual and Reproductive Health": "Saúde Sexual e Reprodutiva",
    "Mosquito net distribution": "Distribuição da rede de mosquitos",
    "WASH items/Water": "Itens WASH/Água",
    "Agricultural tools and seeds": "Ferramentas agrícolas e sementes",
    "Shelter items": "Itens de abrigo",
    Medicine: "Medicamentos",
    "Lost card, phone, sim": "Perda de cartao/telemovel/sim",
    "Covid-19": "Covid-19",
    "Protective Material": "Material de protecao",
    "Beneficiary data security": "Segurança de dados dos beneficiários",
    "Chronic patient": "Paciente crônico",
    "LBL_Individual committing malpractice": "Indivíduo cometendo má pratica",
    "Local Leader": "Líder Local",
    "Community Member": "Membro da Comunidade",
    "Humanitarian actor": "Ator humanitário",
    unknown: "Desconhecido",
    Unknown: "Desconhecido",
    "With Feedback": "Com feedback",
    "Without Feedback": "Sem feedback",
    "Case Close Category": "Como foi encerrado?",
    "Is Closed": "Está encerrado?",
    Closed: "Fechado",
    "Send as case comment": "Enviar como comentário do caso",
    Comments: "Comentários",
    Comment: "Comentário",
    "No comments for this record": "Sem comentários para esse registo",
    "Add your comment": "Adicione seu comentário",
    "Send to Focal Point": "Encaminhar ao ponto focal",
    "Focal Point": "Ponto Focal",
    "Focal Point Notes": "Notas do Ponto Focal",
    "Partner feedback": "Feedback do Parceiro",
    "Referral to": "Encaminhar para",
    "Referral To": "Encaminhar para",
    "Sent at": "Encaminhado aos",
    "Sent At": "Encaminhado aos",
    "Send case to entity": "Encaminhar caso a uma entidade",
    "Send case to Entity": "Encaminhar caso a uma entidade",
    "Add your feedback": "Adicione seu feedback",
    "Isfeedback Aproved": "Feedback aprovado",
    "Is feedback Aproved": "Feedback aprovado",
    Aproved: "Aprovado",
    "Created at": "Criado aos",
    "Created By": "Criado por",
    "Created by": "Criado por",
    "Updated at": "Actualizado aos",
    Case: "Caso",
    Agency: "Agência",
    Agencies: "Agências",
    Region: "Região",
    "Region Name": "Nome da Região",
    "Region name": "Nome da Região",
    Name: "Nome",
    Focalpoints: "Pontos Focais",
    Partners: "Parceiros",
    "Cluster Agency": "Agência do Sector",
    "Cluster agency": "Agência do Sector",
    "Cluster Sector": "Nome do Sector",
    Regions: "Regiões",
    "National Level": "Nível Nacional",
    "National level": "Nível Nacional",
    Received: "Recebidos",
    'Task #': "Tarefa #",
    "Forwarded by": "Reencaminhado por",
    "Has Feedback": "Tem Feedback",
    "Total Cases": "Total de Casos",
    "Referred cases": "Casos encaminhados",
    "Not referred cases": "Casos não encaminhados",
    "Cases with Feedback": "Casos com feedback",
    "Task Title": "Título da Tarefa",
    "Edit Task": "Editar Tarefa",
    "Task Priority": "Prioridade da Tarefa",
    "Categories & Articles": "Categorias & Artigos",
    Categories: "Categorias",
    "Published Date": "Data de Publicação",
    "description": "Descrição",
    "This field is required.": "Este campo é obrigatório.",
    "Expiration Date": "Data de validade",
    Published: "Publicado",
    "Active Revision": "Revisão Ativa",
    "External Link": "Link externo",
    Category: "Categoria",
    Language: "Idioma",
    "Aproved By": "Aprovado por",
    File: "Arquivo",
    "Click here to download file": "Clique aqui para baixar o arquivo",
    Title: "Título",
    Article: "Artigo",
    Portuguese: "Português",
    English: "Inglês",
    "Article Description": "Descrição do artigo",
    article: "Artigo",
    Search: "Pesquisar",
    "Add Focalpoint": "Ad. PontoFocal",
    "Add Focalpoint to agency": "Adicionar um ponto focal a agencia",
    "Add Partner": "Ad. Parceiro",
    "Add Partner to agency": "Adicionar parceiro a agencia",
    From: "De",
    To: "A",
    "Restore Columns": "Restaurar Colunas",
    "Update list": "Actualizar Lista",
    "Export Selected": "Exportar Selecionados",
    "New Record": "Novo Registo",
    "Dedicated Source Used on Website": "Fonte Dedicada Usada no Site",
    "You can find any content by searching for the keyword":
      "Pode encontrar qualquer conteúdo pesquisando a palavra-chave",
    "Search a topic or a keyword": "Pesquise um tópico ou uma palavra-chave",
    "Daily Cases": "Casos do Dia",
    "Weekly Cases": "Casos da Semana",
    "Monthly Cases": "Casos do Mês",
    "Annual Cases": "Casos do Ano",
    "No file uploaded": "Nenhum ficheiro anexado",
    "IDP's": "PID's",
    "Record duplication error": "Erro de duplicação de registro",
    "All Cases - Manager": "Todos os Casos - Gestor",
    "With Feedback - Manager": "Casos com Feedback - Gestor",
    "Without Feedback - Manager": "Casos sem Feedback - Gestor",
    "All Cases - Partner": "Todos os Casos - Parceiro",
    "With Feedback - Partner": "Casos com Feedback - Parceiro",
    "Without Feedback - Partner": "Casos sem Feedback - Parceiro",
    "All Cases": "Todos os casos",
    "Approved Feedback": "Feedbacks Aprovados",
    "Unapproved Feedback": "Feedbacks Não Aprovados",
    Delete: "Apagar",
    "Not published": "Não Publicado",
    "Not Published": "Não Publicado",
    Expired: "Expirado",
    "Your session has expired, please login again!":
      "Sua sessäo expirou, por favor inicie novamente!",
    "Do you want to delete that record?":
      "Tem certeza que deseja apagar esse registo?",
    "Sign Out": "Sair",
    "Select All": "Seleccionar Todos",
    Filter: "Filtro",
    Filters: "Filtros",
    "Date range filters": "Filtros de intervalo de datas",
    Response: "Resposta",
    "Other category": "Outra categoria",
    Month: "Mês",
    "No internet connection available": "Conexão à internet indisponível",
    "Internet connection established": "Conexão à internet reestabelecida",
    New: "Novo",
    "App Notifications": "Notificações da app",
    "You received a new Case": "Recebeu um novo caso",
    "You received a new Case Comment": "Recebeu um novo comentário de caso",
    "New Task Received": "Nova Tarefa Recebida",
    "New Case Received": "Novo Caso Recebido",
    "Case Feedback": "Feedback do caso",
    "New Article": "Novo Artigo",
    "New Task Comment": "Novo Comentário de Tarefa",
    "New Task": "Nova Tarefa",
    "New Case Comment": "Novo Comentário de Caso",
    "Case Forwarding": "Encaminhamento de Caso",
    "This case has already been sent to ":"Este caso já foi enviado para ",
    "Case forwarded to ":"Caso encaminhado para ",
    /**
     * New records
     */

    Cholera: "Cólera",
    Malaria: "Malária",
    "Access/Schedule": "Acesso/Horários",
    "Mosquito Net Distribution": "Distribuição de Redes Mosquiteiras",
    "Access/Medicine": "Acesso/Medicamentos",
    "Access/Distance": "Acesso/Distância",
    "Mosquito Nets": "Rede Mosquiteira",
    "Water Cleaning Products": "Produtos de tratamento de Água",
    "Some records were not saved as they have already been assigned to the selected user(s).": "Um ou mais registo(s) n\ao foi gravdado por já estar encaminhado ao utilizador(s).",
  },
};
